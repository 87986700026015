import React from "react"
import { LayoutSection } from "../../common/layout"
import Token from "../../common/token"
import sandwich from "../../../images/icons/sandwich.svg"
import team from "../../../images/icons/hands.svg"
import birthday from "../../../images/icons/cake.svg"
import skater from "../../../images/icons/skater.svg"
import skate from "../../../images/icons/skate.svg"
import { PastelButton } from "../../common/button"
import { navigate } from "gatsby"
import data from "../../../data/fields"
import { Info } from "../../common/icons"

const Activities = () => {
  const tokens = {
    sandwich: {
      name: "Restaurant",
      icon: sandwich,
    },
    team: {
      name: "Ir con amigos",
      icon: team,
    },
    birthday: {
      name: "Cumpleaños",
      icon: birthday,
    },
    skater: {
      name: "Pista libre",
      icon: skater,
    },
    skate: {
      name: "Comprar accesorios",
      icon: skate,
    },
  }
  return (
    <LayoutSection>
      <div className="w-full bg-polkadot">
        <div className="w-full flex justify-center bg-gradient-radial from-gray-50 via-gray-50">
          <div className="max-w-5xl w-full pt-48 pb-20 flex flex-col items-center justify-center gap-4 px-8">
            <div className="w-full flex justify-between items-center">
              <div className="group -rotate-12">
                <Token
                  front={
                    <img
                      src={tokens.birthday.icon}
                      alt={tokens.birthday.name}
                      className="w-full object-contain"
                    />
                  }
                  back={<span className="md:text-2xl font-bold">Cumpleaños</span>}
                />
              </div>
              <div className="group -translate-y-full">
                <Token
                  front={
                    <img
                      src={tokens.skate.icon}
                      alt={tokens.skate.name}
                      className="w-full object-contain"
                    />
                  }
                  back={<span className="md:text-2xl font-bold">Clases de patinaje</span>}
                />
              </div>
              <div className="group rotate-6 -translate-y-1/2">
                <Token
                  front={
                    <img
                      src={tokens.skater.icon}
                      alt={tokens.skater.name}
                      className="w-full object-contain"
                    />
                  }
                  back={<span className="md:text-2xl font-bold">Patinaje libre</span>}
                />
              </div>
            </div>
            <div className="text-5xl md:text-7xl w-full text-center">
              ¿Qué hacer en Jockey on Ice?
            </div>
            <div className="w-56 flex justify-center">
              <PastelButton onClick={() => navigate(data.url.servicios)}>
                <div className="flex gap-1 justify-center items-center">
                  <Info />
                  <span>Ver más</span>
                </div>
              </PastelButton>
            </div>
            <div className="w-full flex justify-around">
              {/** TODO: Enable this later */}
              {/* <div className="group -rotate-6 translate-y-1/4">
                <Token
                  front={
                    <img
                      src={tokens.sandwich.icon}
                      alt={tokens.sandwich.name}
                      className="w-full object-contain"
                    />
                  }
                  back={<span className="md:text-2xl font-bold">Cafetería</span>}
                />
              </div> */}
              <div className="group rotate-12">
                <Token
                  front={
                    <img
                      src={tokens.team.icon}
                      alt={tokens.team.name}
                      className="w-full object-contain"
                    />
                  }
                  back={<span className="md:text-2xl font-bold">Eventos privados</span>}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutSection>
  )
}

export default Activities
