import React from "react"
import Layout from "../components/common/layout"
import HeadGenerator from "../components/common/seo"
import Activities from "../components/index/activities"
import CallToAction from "../components/index/callToAction"
import Countdown from "../components/index/countdown"
import Features from "../components/index/features"
import PriceBanner from "../components/index/priceBanner"
import data from "../data/fields"
import summaryImage from "../images/summary_image.webp"

const IndexPage = () => {
  return (
    <Layout hasBackground>
      <CallToAction />
      <Countdown />
      <Activities />
      <PriceBanner />
      <Features />
    </Layout>
  )
}

export default IndexPage

export const Head = () => (
  <HeadGenerator
    data={{
      title: data.strings.seo.default.title,
      description: data.strings.seo.default.description,
      url: data.url.index,
      image: {
        srcWebp: summaryImage,
        widh: 1200,
        height: 630,
        altText: data.strings.seo.default.title,
      },
    }}
    largeSummaryImage
  />
)
