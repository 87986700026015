import React from "react"

export const PastelButton = ({ onClick, children, disabled }) => {
  return (
    <button
      onClick={() => onClick && onClick()}
      disabled={disabled}
      className={`px-8 py-3 disabled:border-gray-500 disabled:bg-gray-200 disabled:cursor-not-allowed border-sky-500 bg-sky-100 focus-visible:bg-sky-300 hover:bg-sky-300 border rounded-lg transition-colors duration-300 h-max w-full text-base font-normal`}
    >
      {children}
    </button>
  )
}

export const WideButton = ({ children }) => {
  return (
    <div className="px-8 py-2 bg-white backdrop-blur-sm border rounded-full hover:bg-sky-50 hover:border-sky-500 transition-colors duration-300 h-max w-full text-center font-normal">
      {children}
    </div>
  )
}
