import React from "react"
import { PastelButton } from "../../common/button"
import { LayoutSection } from "../../common/layout"
import { navigate } from "gatsby"
import data from "../../../data/fields"
import { Clock } from "../../common/icons"

const PriceBanner = () => {
  return (
    <LayoutSection className="bg-white">
      <div className="w-full flex max-w-5xl justify-center items-center gap-4 py-6 px-4 xs:px-8 font-bold text-xl">
        <span>No te pierdas la diversión</span>
        <div className="w-72">
          <PastelButton onClick={() => navigate(data.url.reserva)}>
            <div className="flex gap-1 justify-center items-center">
              <Clock />
              <span>Precios y horarios</span>
            </div>
          </PastelButton>
        </div>
      </div>
    </LayoutSection>
  )
}

export default PriceBanner
