import React, { useState, useEffect } from "react"
import { PastelButton } from "../../common/button"
import { LayoutSection } from "../../common/layout"
import { navigate } from "gatsby"
import data from "../../../data/fields"
import { Calendar, Cart } from "../../common/icons"

const Countdown = () => {
  const [target] = useState(new Date("2023-01-09T11:00:00.000Z"))
  const [remaining, setRemaining] = useState({
    active: false,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  useEffect(() => {
    if (target <= new Date()) return () => {}
    const now = new Date()
    const days = parseInt((target - now) / 86400000)
    const hours = parseInt((target - now - days * 86400000) / 3600000)
    const minutes = parseInt((target - now - days * 86400000 - hours * 3600000) / 60000)
    const seconds = parseInt(
      (target - now - days * 86400000 - hours * 3600000 - minutes * 60000) / 1000
    )
    setRemaining({
      active: true,
      days,
      hours,
      minutes,
      seconds,
    })
    const interval = setInterval(() => {
      const now = new Date()
      const days = parseInt((target - now) / 86400000)
      const hours = parseInt((target - now - days * 86400000) / 3600000)
      const minutes = parseInt((target - now - days * 86400000 - hours * 3600000) / 60000)
      const seconds = parseInt(
        (target - now - days * 86400000 - hours * 3600000 - minutes * 60000) / 1000
      )
      setRemaining({
        active: true,
        days,
        hours,
        minutes,
        seconds,
      })
    }, 1000)
    return () => clearInterval(interval)
  }, [target])

  return (
    <LayoutSection className="bg-white backdrop-blur-sm">
      <div className="w-full flex max-w-5xl justify-center gap-4 py-6 px-8 font-bold text-xl">
        <div className="w-full flex flex-col sm:flex-row justify-center items-center gap-4 transition-all duration-1000">
          {/** TODO: Disable this later */}
          <div className="w-56 sm:m-0">
            <PastelButton onClick={() => navigate(data.url.reserva)}>
              <div className="flex gap-1 justify-center items-center">
                <Calendar />
                <span>Reserva ahora</span>
              </div>
            </PastelButton>
          </div>
          {/** TODO: Enable this later */}
          {/* <div className="w-56 mr-auto sm:m-0">
              <PastelButton onClick={() => navigate(data.url.tienda)}>
                <div className="flex gap-1 justify-center items-center">
                  <Cart />
                  <span>Vamos a la tienda</span>
                </div>
              </PastelButton>
            </div>
            <div className="w-56 ml-auto sm:m-0">
              <PastelButton onClick={() => navigate(data.url.reserva)}>
                <div className="flex gap-1 justify-center items-center">
                  <Calendar />
                  <span>Reserva ahora</span>
                </div>
              </PastelButton>
            </div> */}
        </div>
      </div>
    </LayoutSection>
  )
}

export default Countdown
