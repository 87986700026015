import React from "react"
import { LayoutSection } from "../../common/layout"

const Section = ({ children }) => {
  return (
    <div className="w-full flex flex-col max-w-5xl justify-center items-center gap-4 py-20 px-8 font-bold text-2xl text-center">
      {children}
    </div>
  )
}

const Features = () => {
  return (
    <LayoutSection className="bg-gradient-mirror-wide from-white/0 to-gray-50">
      <div className="flex flex-col divide-y divide-sky-500">
        <Section>Ideal para principiantes y avanzados</Section>
        <Section>Ven con toda la familia, amigos o pareja</Section>
        <Section>Estamos al costado del Centro de Convenciones del Jockey</Section>
      </div>
    </LayoutSection>
  )
}

export default Features
