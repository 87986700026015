import React, { useState } from "react"

const Token = ({ front, back }) => {
  const [flip, setFlip] = useState()

  const children = (
    <>
      <div
        className={`${
          flip ? "opacity-0" : ""
        } absolute inset-0 flex justify-center items-center transition-opacity duration-300 p-3 md:p-6`}
      >
        {front}
      </div>
      <div
        className={`${
          flip ? "" : "opacity-0"
        } absolute inset-0 flex justify-center items-center transition-opacity duration-300`}
      >
        {back}
      </div>
    </>
  )
  return back ? (
    <button
      onClick={() => back && setFlip(!flip)}
      className={`relative w-24 h-24 aspect-square md:h-40 md:w-40 text-center rounded-full overflow-hidden transition-[width,height] duration-300 border border-sky-500 bg-white`}
    >
      {children}
    </button>
  ) : (
    <div
      className={`relative w-24 h-24 aspect-square md:h-40 md:w-40 text-center rounded-full overflow-hidden transition-[width,height] duration-300 border border-sky-500 bg-white`}
    >
      {children}
    </div>
  )
}

export default Token
