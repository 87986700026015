import { navigate } from "gatsby"
import React from "react"
import data from "../../../data/fields"
import patines from "../../../images/skates.webp"
import { PastelButton } from "../../common/button"
import { SimplePaddedCard } from "../../common/cards"
import { Ticket } from "../../common/icons"
import { LayoutSection } from "../../common/layout"

const CTA = () => {
  return (
    <LayoutSection>
      <div className="w-full bg-polkadot overflow-hidden">
        <div className="flex justify-center bg-gradient-radial from-gray-50 via-gray-50 to-transparent w-full md:-translate-x-96">
          <div className="w-full flex-col md:flex-row flex max-w-5xl justify-between md:gap-4 p-8 md:translate-x-96">
            <div className="w-1/2 mx-auto md:mx-0 -translate-y-1/4 -mb-10 md:-mb-16 flex justify-center md:justify-start">
              <img
                alt="patines"
                src={patines}
                className="w-full transition-transform animate-wiggle origin-top"
              />
            </div>
            <div className="h-full flex items-center w-max max-w-full mx-auto">
              <SimplePaddedCard>
                <div className="flex flex-col justify-center items-center md:items-end gap-4">
                  <span className=" font-bold bg-sky-100 rounded-full px-2 z-20">
                    Desde el 9 de enero
                  </span>
                  <p className="text-2xl max-w-sm text-center md:text-end">
                    Vive la emoción de patinar sobre hielo en el Jockey Club
                  </p>
                  <div className="w-56">
                    <PastelButton onClick={() => navigate(data.url.reserva)}>
                      <div className="flex gap-1 justify-center items-center">
                        <Ticket />
                        <span>Compra entradas</span>
                      </div>
                    </PastelButton>
                  </div>
                </div>
              </SimplePaddedCard>
            </div>
          </div>
        </div>
      </div>
    </LayoutSection>
  )
}

export default CTA
